<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading" />
    <input
      ref="csvFileWithPrices"
      type="file"
      hidden
      accept=".xlsx"
      @change="uploadPrices($event)"
    />
    <input
      ref="csvFileWithPricesReset"
      type="file"
      hidden
      accept=".xlsx"
      @change="uploadPrices($event, true)"
    />

    <div class="custom-card">
      <div class="custom-card_header">
        <p class="custom-card__text">
          Список товаров поставщика : {{ providerName }}
        </p>
      </div>
      <div class="custom-card__title">
        <div class="custom-card__btns-block_box">
          <!-- блок с поиском и сортировками -->
          <div class="search">
            <v-text-field
              v-model="findTextByName"
              label="Поиск по названию..."
              :hint="findTextByName ? '' : 'Например: apple iPhone 13 черный'"
              class="search_input"
              hide-details
              color="#51C8F0"
              solo
              @keyup.enter="findProducts()"
              @input="checkText($event)"
            >
              <v-btn
                v-if="findTextByName"
                slot="append"
                color="red"
                icon
                @click="clearInput()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn slot="append" color="primary" icon @click="findProducts()">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-text-field>
            <v-select
              v-model="currentFilter"
              :items="Object.values(statuses)"
              item-text="name"
              item-value="value"
              hide-details
              color="#51C8F0"
              solo
              class="search_input"
              @change="changePage(0)"
            ></v-select>
            <v-btn text @click="goToPage()"
              ><v-icon color="#5d4bd0" class="mr-2">mdi-plus-circle</v-icon
              >Добавить товар
            </v-btn>
          </div>
        </div>
        <div class="custom-card__btns-block_box">
          <v-btn large text @click="limitModal = true"
            ><v-icon large color="#F3993E" class="mr-2"
              >mdi-file-document</v-icon
            >Лимиты на остаток
          </v-btn>
          <v-btn large text @click="exelModal = true"
            ><v-icon large color="#e93e7b" class="mr-2">mdi-cog-outline</v-icon
            >Работа с товарами
          </v-btn>
        </div>
      </div>

      <p v-if="noFindProducts">По данному запросу ничего не найдено</p>
      <p v-if="noProducts">У данного поставщика пока нет товаров</p>
      <div v-else class="custom-card__container">
        <div class="custom-table">
          <div class="head">
            <div
              v-for="item in items.head"
              :key="item.id"
              class="head__item"
              :class="item.id === 1 ? 'img' : item.id === 2 && 'name'"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-for="item in items.body" :key="item.providerProductId.id">
            <div class="value">
              <div class="value__item_img">
                <img
                  v-if="item.providerProductId.images.length"
                  :src="item.providerProductId.images[0].fileUrl"
                  :alt="item.providerProductId.images[0].alt"
                  class="img-vuetify"
                />
                <p v-else>Нет фото</p>
              </div>
              <div class="value__item_name">
                {{ item.providerProductId.name }}
              </div>
              <div class="value__item" :class="statuses[item.status].class">
                {{ statuses[item.status].name }}
                <v-btn
                  v-if="statuses[item.status].class === 'rejected'"
                  icon
                  large
                  @click="
                    rejectModal = true;
                    curComment = item.comment;
                  "
                >
                  <v-icon color="error">mdi-information-variant-circle</v-icon>
                </v-btn>
              </div>
              <div class="value__item">
                <v-btn
                  icon
                  large
                  color="#5d4bd0"
                  @click="goToPage(item.providerProductId.id)"
                >
                  <v-icon>mdi-pencil-circle</v-icon>
                </v-btn>
              </div>
              <div class="value__item">
                <v-btn
                  icon
                  large
                  color="#e93e7b"
                  @click="goToPage(item.providerProductId.id, true)"
                >
                  <v-icon>mdi-text-box</v-icon>
                </v-btn>
              </div>
              <div class="value__item">
                <v-btn icon large color="#F3993E" @click="openInfoModal(item)">
                  <v-icon>mdi-text-box</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-pagination
        v-if="pages.count > 1"
        :value="pages.current + 1"
        :dark="$dark.get()"
        :total-visible="7"
        :length="pages.count"
        @input="changePage($event - 1)"
      ></v-pagination>
    </div>
    <v-dialog v-model="priceModal" activator="parent" width="50%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-actions class="modal_actions">
          <v-select
            v-model="selectedDepartment"
            class="modal__select"
            label="Филиалы"
            :items="departmens"
            item-text="name"
            item-value="id"
          ></v-select>
          <v-btn color="warning" @click="$refs.csvFileWithPrices.click()"
            >Загрузить без обнуления<v-icon right
              >mdi-tray-arrow-up</v-icon
            ></v-btn
          >
          <p class="modal_text">
            Этот способ загрузки изменяет цены и остатки товарам, которые
            находятся в файле. Остальные товары поставщика не будут затронуты
          </p>
          <v-btn color="primary" @click="$refs.csvFileWithPricesReset.click()"
            >Загрузить с обнулением<v-icon right
              >mdi-tray-arrow-up</v-icon
            ></v-btn
          >
          <p class="modal_text">
            При выборе этого способа, поставщик соглашается с тем, что все
            товары, которые отсутствовали в файле загрузки цен, будут скрыты.
            Так же их остаток будет обнулён (balance = 0).
          </p>
          <v-btn @click="priceModal = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rejectModal" activator="parent" width="50%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title class="modal_close">
          <v-btn text color="black" @click="rejectModal = false">Закрыть</v-btn>
        </v-card-title>
        <v-card-text>
          <p>Отклонено администратором системы</p>
          <p>
            Тег : <span class="modal_tag">{{ curComment }}</span>
          </p>
        </v-card-text>
        <v-card-actions class="modal_actions">
          <v-btn text @click="rejectModal = false"
            >Связаться с администратором</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <InfoDialog :info-modal.sync="infoModal" :redacting-obj="redactingObj" />
    <InstructionsDialog
      :instruction-type="firstIntruction"
      :instructions-modal.sync="instructionsModal"
    />
    <ProductLimits :modal.sync="limitModal" :provider-id="$route.params.id" />
    <ExelModal
      :exel-modal.sync="exelModal"
      @insructions="openInstructionsModal($event)"
      @price-modal="priceModal = true"
      @refresh="setValues()"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loader from "@/components/Loader";
import InstructionsDialog from "@/components/ProviderProducts/InstructionsDialog.vue";
import InfoDialog from "@/components/ProviderProducts/InfoDialog.vue";
import ProductLimits from "@/components/ProviderProducts/ProductLimits.vue";
import ExelModal from "@/components/ProviderProducts/ExelModal.vue";
// Вызывает сообщение об успешной операции, либо ошибку если введен текст
import showMessage from "@/Functions/message";
import api from "@/api";

export default {
  components: {
    loader,
    InstructionsDialog,
    InfoDialog,
    ProductLimits,
    ExelModal,
  },
  data() {
    return {
      loading: false,
      noProducts: false,
      limitModal: false,
      exelModal: false,
      modal: false,
      isRedacting: false,
      show: false,
      priceModal: false,
      instructionsModal: false,
      firstIntruction: "",
      providerName: "",
      infoModal: false,
      redactingObj: {
        providerProductId: {
          barcode: "",
          guid: "",
          description: "",
          shortDescription: "",
          name: "",
          images: [{ fileUrl: "" }],
        },
        prices: [],
      },
      items: {
        head: [
          { id: 1, name: "Фото" },
          { id: 2, name: "Товар" },
          { id: 3, name: "Статус" },
          { id: 4, name: "Редактировать товар" },
          { id: 5, name: "Редактировать цены и остатки" },
          { id: 6, name: "Информация о товаре" },
        ],
        body: [],
      },
      pages: {
        current: 0,
        count: 0,
      },
      request: {
        size: 50,
        page: 0,
      },
      selectedDepartment: 0,
      departmens: [],
      findTextByName: "",
      noFindProducts: false,
      currentFilter: "APPROVED",
      statuses: {
        APPROVED: {
          name: "В продаже",
          class: "approved",
          value: "APPROVED",
        },
        REJECTED: {
          name: "Отклонен",
          class: "rejected",
          value: "REJECTED",
        },
        PENDING: {
          name: "На модерации",
          class: "pending",
          value: "PENDING",
        },
      },
      rejectModal: false,
      curComment: "",
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Providers/STATE",
      CITY: "City/STATE",
      DEPARTMENT: "Department/STATE",
    }),
  },
  mounted() {
    this.checkLS();
    this.getProviderName();
    this.departmens = this.DEPARTMENT.cityDepartment;
    this.selectedDepartment = this.departmens[0].id;
  },
  methods: {
    ...mapActions({
      getProviderProducts: "Providers/GET_PROVIDER_PRODUCTS",
      getAllProviders: "Providers/GET_ALL_PROVIDERS",
      GET_PROVIDER_PRODUCTS_WITH_FILTER:
        "Providers/GET_PROVIDER_PRODUCTS_WITH_FILTER",
    }),
    //Подгружает все товары
    async setValues() {
      this.loading = true;
      this.request.providerId = +this.$route.params.id;
      this.request.status = this.currentFilter;
      await this.GET_PROVIDER_PRODUCTS_WITH_FILTER(this.request);
      if (this.STATE.providerProducts.content) {
        this.items.body = this.STATE.providerProducts.content;
        this.pages.count = this.STATE.providerProducts.totalPages;
        this.pages.current = this.STATE.providerProducts.number;
        this.noProducts = false;
      } else {
        this.noProducts = true;
      }
      this.loading = false;
    },
    async uploadPrices(csvFile, withReset) {
      this.loading = true;
      let data = new FormData();
      data.append("file", csvFile.target.files[0]);
      let request = {
        file: data,
        providerId: this.$route.params.id,
        departmentId: this.selectedDepartment,
      };
      let response;
      if (withReset) {
        response = await api.Providers.importUpdateFileWithPricesWithReset(
          request
        );
        this.$refs.csvFileWithPricesReset.value = "";
      } else {
        response = await api.Providers.importUpdateFileWithPrices(request);
        this.$refs.csvFileWithPrices.value = "";
      }
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage();
        await this.setValues();
      }

      this.priceModal = false;
      this.loading = false;
    },
    // Определяет имя поставщика основываясь на общем стейте, если он пуст перекидвает на пред страницу
    async getProviderName() {
      if (this.STATE.allProviders.length > 0) {
        this.setValues();
        this.providerName = this.STATE.allProviders.find(
          (provider) => provider.id == this.$route.params.id
        ).name;
      } else {
        await this.getAllProviders();
        this.getProviderName();
      }
    },
    openInfoModal(providerObj) {
      this.redactingObj = providerObj;
      console.log(this.redactingObj);
      this.infoModal = true;
    },

    openInstructionsModal(insructionType) {
      this.firstIntruction = insructionType;
      this.instructionsModal = true;
    },
    changePage(page) {
      this.request.page = page;
      this.setValues();
    },
    async findProducts() {
      if (!this.findTextByName) {
        return;
      }
      let payload = {
        ...this.request,
        name: this.findTextByName,
      };
      this.loading = true;
      const response = await api.Products.findProviderProducts(payload);
      this.items.body = response.content;
      this.pages.current = response.number;
      this.pages.count = response.totalPages;
      this.loading = false;
      if (response.content.length === 0) {
        this.noFindProducts = true;
      } else {
        this.noFindProducts = false;
      }
    },
    clearInput() {
      this.findTextByName = "";
      this.noFindProducts = false;
      this.items.body = this.STATE.providerProducts.content;
      this.pages.count = this.STATE.providerProducts.totalPages;
      this.pages.current = this.STATE.providerProducts.number;
    },
    // если убрали весь текст из ввода возвращает исходные продукты
    checkText(text) {
      if (!text) {
        this.clearInput();
      }
    },
    goToPage(productId, pricePage) {
      const body = {
        page: this.request.page,
        status: this.request.status,
      };
      sessionStorage.setItem("AyanProviderPage", JSON.stringify(body));
      if (!productId) {
        this.$router.push(`/search-product/${this.$route.params.id}`);
      } else if (pricePage) {
        this.$router.push(
          `/redact-product-price/${productId}-${this.$route.params.id}`
        );
      } else {
        this.$router.push("/products/" + productId);
      }
    },
    checkLS() {
      let ls = sessionStorage.getItem("AyanProviderPage");
      if (ls) {
        const data = JSON.parse(ls);
        this.currentFilter = data.status;
        this.request.page = data.page;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
  &_input {
    max-width: 250px;
    border: #51c8f0 2px solid;
    border-radius: 15px;
  }
  &_block {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &_text {
    color: black;
    font-weight: bold;
  }
}
.pending {
  color: gray;
}
.approved {
  color: #73d6ad;
}
.rejected {
  color: red;
}
.custom-card {
  position: relative;
  &_header {
    background: linear-gradient(to right, #e93e7b 50%, white 70%);
    margin-left: -12px;
    color: white;
    padding: 5px 10px;
    margin-bottom: 10px;
  }
  &__title {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media screen and (max-width: 800px) {
      font-size: 16px;
      flex-direction: column;
    }
  }
  &__btns-block {
    display: flex;
    gap: 5px;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
    &_box {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
  &__text {
    margin: 0;
  }

  .custom-card__container {
    .custom-table {
      .head {
        display: flex;
        justify-content: space-between;
        background: linear-gradient(to right, #5d4bd0, #e93e7b);
        &__item {
          text-align: left;
          font-weight: 500;
          width: 20%;
          text-align: center;
          color: white;
        }
      }
      .img {
        width: 10%;
        text-align: center;
      }
      .name {
        width: 50%;
        text-align: left;
        padding-left: 30px;
      }
      .value {
        display: flex;
        justify-content: space-between;
        padding: 5px 0 5px 5px;
        border-bottom: 1px solid #e93e7a45;
        &__item {
          width: 20%;
          display: flex;
          align-items: center;
          justify-content: center;
          &_img {
            min-width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            border: 1px solid #d9d9d9;
            border-radius: 7px;
          }
          &_name {
            display: flex;
            align-items: center;
            width: 50%;
            justify-content: left;
            color: #4395d5;
            padding-left: 15px;
          }
        }
        &__btn {
          width: 100%;
        }
      }
    }
  }
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_close {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  &_tag {
    color: red;
  }
  &_actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  &__select {
    max-width: 80%;
  }
  &__title {
    text-align: center;
    font-size: large;
  }
  &_text {
    text-align: center;
  }
  &__li {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }
}
.img-vuetify {
  max-height: 100px !important;
  max-width: 100px;
}
</style>
